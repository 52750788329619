<template>
  <q-form @submit="handleAddressAdd" ref="addressForm" @reset="onReset">
    <div class="scheduleList">
      <q-scroll-area
        :thumb-style="scrollAreaThumbStyle"
        :bar-style="scrollAreaBarStyle"
        :visible="scrollAreaVisible"
        class="reserve-scroll-area"
        ref="addAddressScrollArea"
      >
        <div>
          <div
            class="addressSelect"
            v-if="defaultAddress && defaultAddress.formatAddress"
          >
            <div class="row">
              <div class="col-sm-6">
                <h6> {{ defaultAddress.fullName }} </h6>
                <p v-html="defaultAddress.formatAddress" />
              </div>
            </div>
          </div>
          <div class="searchAddress">
            <div class="addAddressForm">
              <AddressField
                :addressFieldChange="addressFieldChange"
                :addressObj="addressObj"
              />
            </div>
          </div>
        </div>
      </q-scroll-area>
      <div class="row wrap no-wrap-md items-center q-pt-md">
        <div
          class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3"
        >
          <div class="row">
            <div class="col-6 col-sm-6 col-md-6 q-pr-sm q-pl-sm">
              <div class="form-action">
                <q-btn
                  rounded
                  no-caps
                  unelevated
                  color="tertiary"
                  label="Cancel"
                  class="full-width"
                  size="1rem"
                  type="reset"
                  @click="
                    () => {
                      this.updateField('addAddress', false)
                      this.updateField('changeAddress', true)
                    }
                  "
                />
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-6 q-pr-sm q-pl-sm">
              <div class="form-action">
                <q-btn
                  rounded
                  no-caps
                  unelevated
                  color="primary"
                  label="Save and continue"
                  class="full-width"
                  size="1rem"
                  type="submit"
                  :loading="addingAddress"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </q-form>
</template>
<script>
import { mapGetters } from 'vuex'
import AddressField from 'components/address/AddressField'

export default {
  name: 'ReserveAddAddress',
  props: {
    updateField: {
      type: Function,
      default: () => {},
    },
  },
  components: { AddressField },
  computed: {
    ...mapGetters('address', ['defaultAddress']),
  },
  data() {
    return {
      addingAddress: false,
      addressObj: {
        firstName: '',
        lastName: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
        country: 'US',
        phone: '',
        securityCode: '',
        instruction: '',
        markAsDefault: false,
        geoLocation: '',
      },
    }
  },
  methods: {
    async handleAddressAdd() {
      let reqData = { ...this.addressObj }
      if (!reqData.geoLocation) {
        this.$store.commit('address/SET_ADDRESS_ALERT', {
          alertKey: 'addAddressAlert',
          alertData: {
            variant: 'danger',
            message:
              'Unable to validate address. When typing your address, please select the Google result that matches.',
          },
        })
        this.scrollToPosition(this.$refs.addAddressScrollArea, 0, 100)
        return
      }
      this.addingAddress = true

      if (reqData.phone)
        reqData.phone = this.unmask(reqData.phone, this.phoneMask)

      let data = await this.$store.dispatch(
        'address/addOrUpdateAddress',
        reqData
      )
      this.addingAddress = false
      if (data) {
        this.updateField('addAddress', false)
        this.updateField('changeAddress', true)

        this.onReset()
      }
    },
    onReset() {
      this.addressObj = {
        firstName: '',
        lastName: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
        country: 'US',
        phone: '',
        securityCode: '',
        instruction: '',
        markAsDefault: false,
      }
      this.$refs.addressForm.resetValidation()
    },
    addressFieldChange(field, val) {
      this.addressObj[field] = val
    },
  },
}
</script>
